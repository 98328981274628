<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="header">
      <h1>智慧小区</h1>
      <p style="text-indent: 2em">
        智慧小区是指充分利用互联网、物联网、大数据、云计算等新一代信息技术的集成应用，从与小区相关的法律法规、行业政策、物业信息、物业服务等方面，为小区居民提供安全、舒适、便利的现代化和智慧化的生活环境。
      </p>
    </div>
    <div class="target">
      <h2 class="title">建设目标</h2>
      <div>
        <div class="target_title">
          <span class="target_icon icon1"></span>
          <span class="target_text">安全</span>
        </div>
        <div class="target_content">
          通过对流动人员管理、出入人员管理等手段，安防感知设备、消防感知设备的建设，及时告警信息，小区、警、消防联动，保障小区安全。
        </div>
      </div>
      <div>
        <div class="target_title">
          <span class="target_icon icon2"></span>
          <span class="target_text">展现</span>
        </div>
        <div class="target_content">
          建设统一管理平台，展现小区沙盘，通过图层叠加具象化小区实时情况，精确报警点位，给小区管理、物业、公安、社区、街道相关部门提供精确的数据服务。
        </div>
      </div>
      <div>
        <div class="target_title">
          <span class="target_icon icon3"></span>
          <span class="target_text">服务</span>
        </div>
        <div class="target_content">
          建立访客管理、停车管理、物流管理手段，提供电动车充电、防盗服务，提供一体化养老、健康服务、信息化物业管理服务。
        </div>
      </div>
    </div>
    <div class="framework">
      <div class="title">平台架构</div>
      <div class="img3">
        <img
          style="width: 100%; height: 100%"
          @click="onYuLan(img)"
          :src="img"
          alt=""
        />
      </div>
    </div>
    <div class="title">功能场景</div>
    <div class="scene">
      <van-tabs
        v-model="active"
        swipeable
        :swipe-threshold="3"
        title-active-color="#365EF1"
        background="#F2F3F8"
      >
        <van-tab v-for="(item, index) in list" :key="index" :title="item.name">
          <img :src="item.img" alt="" class="sceneImg" />
          <h1 class="title">{{ item.name }}</h1>
          <div v-if="item.content">
            <div v-for="(el, i) in item.content" :key="i">
              <h4>{{ el.title }}</h4>
              <p>{{ el.text }}</p>
            </div>
          </div>
          <p v-else>{{ item.text }}</p>
          <div class="detail" @click="go(item.id)">查看详情</div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
import { ImagePreview } from "vant";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      img: require("../../assets/image/residentialQuartersImg/组 290.png"),
      active: 0,
      list: [
        {
          id: 0,
          name: "可视化大屏",
          img: require("../../assets/assets/residentialQuartersImg/img4.png"),
          text: "平台可根据街道、社区、物业不同的职能范围进行首页定制，满足不同层次的管理需要，建立集政府监督、预警联动、智能分析、物业管理、便民服务等于一体的智慧社区业务管理平台，打造小事不出网格，大事不出村社的基层治理新样板。",
        },
        {
          id: 1,
          name: "出入分析系统",
          img: require("../../assets/assets/residentialQuartersImg/img5.png"),
          content: [
            {
              text: "通过物联设备，对出入口人员通道控制系统进行管理，使业主、租户刷脸进出小区。",
              title: "人行分析",
            },
            {
              text: "通过车牌识别摄像头一体机对进出车辆进行控制，分析进入车辆身份。结合地磁感应有效管理车位信息。",
              title: "车行分析",
            },
          ],
          text: "",
        },
        {
          id: 2,
          name: "视频信息管理系统",
          img: require("../../assets/assets/residentialQuartersImg/img6.png"),
          text: "采用实景指挥系统，通过小区/社区高点图像，统筹全局，联动细节。",
        },
        {
          id: 3,
          name: "研判分析",
          img: require("../../assets/assets/residentialQuartersImg/img7.png"),
          text: "以人、事、物、地址、组织等为要素，全面汇集整合小区各类信息资源，使用数据分析和挖掘技术，建立相应的实战应用模型，通过数据碰撞，挖掘出能够提示犯罪运行和变化趋势方向的情报信息，提高小区的安全度。",
        },
      ],
    };
  },

  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
    go(id) {
      this.$router.push({
        path: "/m_residentialQuartersDetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 20px 0;
  }
  .header {
    height: 480px;
    background: url("../../assets/assets/residentialQuartersImg/header.png")
      no-repeat;
    background-size: 100% 100%;
    padding-left: 10px;
    > h1 {
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      padding-top: 100px;
      margin: 0;
    }
    > p {
      padding: 20px 10px 0 0px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .target {
    background-color: #f2f3f8;
    > div:last-child {
      margin-bottom: 0px;
    }
    > div {
      width: calc(100% - 20px);
      height: 130px;
      background: url("../../assets/assets/residentialQuartersImg/img1.png")
        no-repeat;
      background-size: 100% 100%;
      margin-left: 10px;
      margin-bottom: 10px;
      .target_title {
        height: 36px;
        width: 100px;
        background: url("../../assets/assets/residentialQuartersImg/img2.png")
          no-repeat;
        background-size: contain;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        .target_icon {
          display: inline-block;
          width: 18px;
          height: 18px;

          vertical-align: middle;
          margin-right: 10px;
        }
        .icon1 {
          background: url("../../assets/assets/residentialQuartersImg/icon1.png")
            no-repeat;
          background-size: contain;
        }
        .icon2 {
          background: url("../../assets/assets/residentialQuartersImg/icon2.png")
            no-repeat;
          background-size: contain;
        }
        .icon3 {
          background: url("../../assets/assets/residentialQuartersImg/icon3.png")
            no-repeat;
          background-size: contain;
        }
        .target_text {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
      }
      .target_content {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #6b6b6b;
        margin: 10px;
        text-indent: 2em;
      }
    }
  }
  .framework {
    .img3 {
      width: calc(100% - 20px);
      height: 210px;
      background: url("../../assets/image/residentialQuartersImg/组 290.png")
        no-repeat;
      background-size: contain;
      margin: 0px 0 0 10px;
    }
  }
  .scene {
    width: calc(100% - 20px);
    margin-left: 10px;
    background-color: #fff;
    padding-bottom: 20px;

    .sceneImg {
      width: 100%;
      height: 180px;
      padding-top: 20px;
      background-color: #f2f3f8;
    }
    p {
      text-indent: 2em;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      padding: 0 10px;
    }
    .detail {
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid rgba(54, 94, 241, 0.5);
      border-radius: 36px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      opacity: 1;
      position: relative;
      left: 0;
      right: 0;

      margin: auto;
      margin-top: 20px;
    }
    h4 {
      font-size: 14px;
      text-align: center;
      color: #333;
    }
  }
}
::v-deep .van-tabs__line {
  background-color: #365ef1;
}
</style>
